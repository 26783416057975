import { Injectable, OnDestroy } from '@angular/core';
import { NgEventBus } from 'ng-event-bus';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeviceFacadeService } from '../facades/device-facade.service';
import { Device } from '../../models/auth.model';
import { ServerSentEventService } from './server-sent-event.service';
import { decodeJwt, JWTPayload } from 'jose';
import { ViewContentCacheService } from '../cache/view-content-cache.service';
import { ViewContent } from '../../models/view-content.models/view-content.model';

/**
 * Service for handling Server-Sent Events (SSE) and managing device-related events.
 */
@Injectable({
    providedIn: 'root',
})
export class ServerSentEventHandlerService implements OnDestroy {
    /** Current device information */
    private currentDevice: Device | null = null;
    /** Set of active topics to prevent duplicate subscriptions */
    private activeTopics = new Set<string>();
    /** Array to store all subscriptions for easy cleanup */
    private allSubs: Subscription[] = [];

    private isSubscribed: boolean = false; // Flag to track subscriptions

    private processedCount: number = 0;

    private locatorStatusSubject = new BehaviorSubject<{
        remaining: number;
        processed: number;
        locatorLog: { locator: string; success: boolean }[];
    }>({
        remaining: 0,
        processed: this.processedCount,
        locatorLog: [], // Initialisiere als leeres Array
    });

    // Observable für den Fortschritt
    public locatorStatus$: Observable<{
        remaining: number;
        processed: number;
        locatorLog: { locator: string; success: boolean }[];
    }> = this.locatorStatusSubject.asObservable();

    constructor(
        private readonly eventBus: NgEventBus,
        private readonly deviceFacade: DeviceFacadeService,
        private readonly sseService: ServerSentEventService,
        private vcCache: ViewContentCacheService
    ) {
        // Initialisiere die Subscriptions zu ViewContentCacheService
        this.initializeViewContentCacheSubscriptions();
    }

    public initializeMercureTopicSubscriptions(accessToken: string): void {
        if (this.isSubscribed) {
            console.warn('Already subscribed. Skipping initialization.');
            return; // If already subscribed, skip reinitializing
        }

        if (!this.isValidToken(accessToken)) {
            console.error('Invalid access token provided.');
            return;
        }

        this.isSubscribed = true; // Mark as subscribed to prevent duplicate calls

        // Subscribe to device changes
        this.allSubs.push(
            this.deviceFacade.device$.subscribe({
                next: (deviceState) => this.handleDeviceChange(deviceState),
                error: (error) => this.handleError(error, 'deviceSubscription'),
            })
        );

        // Subscribe to device ID changes
        this.allSubs.push(
            this.deviceFacade.getDeviceId().subscribe({
                next: (deviceId: string) => {
                    this.handleDeviceIdChange(deviceId);
                },
                error: (error) => this.handleError(error, 'deviceIdSubscription'),
            })
        );

        // Subscribe to periodic ping events
        this.subscribeToServerSentEvents(['periodicPing'], (eventData: any) => {
            this.eventBus.cast('sse:periodicPing', eventData);
        });
    }

    /**
     * Cleans up resources when the service is destroyed.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((sub) => sub.unsubscribe());
        this.activeTopics.clear();
        this.clearSensitiveData();
    }

    public updateLocatorStatus(
        remaining: number,
        processed: number,
        locatorLog: {
            locator: string;
            success: boolean;
        },
        resetStatus: boolean = false
    ): void {
        let currentStatus: any;
        let updatedLog: any;

        if (!resetStatus) {
            // Hole den aktuellen Status
            currentStatus = this.locatorStatusSubject.getValue();

            // Füge das neue Log zum bestehenden Log-Array hinzu
            updatedLog = [...currentStatus.locatorLog, locatorLog];
        } else {
            // Füge das neue Log zum bestehenden Log-Array hinzu
            updatedLog = [];
        }
        // Aktualisiere den Status mit dem neuen Log-Array
        this.locatorStatusSubject.next({
            remaining,
            processed,
            locatorLog: updatedLog, // Setze das aktualisierte Log-Array
        });
    }

    /**
     * Initialisiert die Subscriptions zu incomingLocator$ und incomingViewContents$.
     */
    private initializeViewContentCacheSubscriptions(): void {
        // Subscription für eingehende Locator
        const locatorSub = this.vcCache.incomingLocator$.subscribe({
            next: (locator: string) => {
                this.handleIncomingLocator(locator);
            },
            error: (error) => this.handleError(error, 'incomingLocator$ Subscription'),
        });

        // Subscription für eingehende ViewContents
        const viewContentSub = this.vcCache.incomingViewContents$.subscribe({
            next: (viewContent) => {
                this.handleIncomingViewContent(viewContent);
            },
            error: (error) => this.handleError(error, 'incomingViewContents$ Subscription'),
        });

        this.allSubs.push(locatorSub, viewContentSub);
    }

    /**
     * Handhabt einen neu empfangenen Locator.
     * @param locator - Der empfangene Locator als String.
     */
    private handleIncomingLocator(locator: string): void {
        const currentStatus = this.locatorStatusSubject.getValue();
        const updatedRemaining = currentStatus.remaining + 1;
        const updatedLog = [...currentStatus.locatorLog, { locator, success: false }];

        this.locatorStatusSubject.next({
            remaining: updatedRemaining,
            processed: currentStatus.processed,
            locatorLog: updatedLog,
        });
    }

    /**
     * Handhabt einen neu empfangenen ViewContent.
     * @param viewContent - Der empfangene ViewContent.
     */
    private handleIncomingViewContent(viewContent: ViewContent<any>): void {
        const currentStatus = this.locatorStatusSubject.getValue();

        const locator = (viewContent as any).locator as string;

        // Finde den entsprechenden Log-Eintrag
        const logIndex = currentStatus.locatorLog.findIndex((log) => log.locator === locator && !log.success);

        if (logIndex !== -1) {
            // Aktualisiere den Log-Eintrag auf success: true
            const updatedLog = [...currentStatus.locatorLog];
            updatedLog[logIndex] = { locator, success: true };

            this.locatorStatusSubject.next({
                remaining: currentStatus.remaining - 1,
                processed: currentStatus.processed + 1,
                locatorLog: updatedLog,
            });
        } else {
            console.warn(`Kein passender Locator für ViewContent gefunden: ${locator}`);
        }
    }

    /**
     * Handles device state changes.
     * @param deviceState - The current device state.
     */
    private handleDeviceChange(deviceState: { device: Device | null }): void {
        const deviceId = deviceState.device?.deviceId;
        if (deviceId && !this.activeTopics.has(`device/${deviceId}`)) {
            this.currentDevice = { ...this.currentDevice, deviceId };
            this.subscribeToServerSentEvents([`device/${deviceId}`], (eventData: any) => {
                this.eventBus.cast(`sse:device/${deviceId}`, eventData);
            });
        }
    }

    /**
     * Handles device ID changes.
     * @param deviceId - The new device ID.
     */
    private handleDeviceIdChange(deviceId: string): void {
        if (deviceId && !this.activeTopics.has(`sse:device/${deviceId}`)) {
            this.allSubs.push(
                this.eventBus.on(`sse:device/${deviceId}`).subscribe({
                    error: (error) => this.handleError(error, 'eventBusSubscription'),
                })
            );
        }
    }

    /**
     * Subscribes to server-sent events for given topics.
     * @param topics - Array of topics to subscribe to.
     * @param onNext - Callback function for handling incoming events.
     */
    private subscribeToServerSentEvents(topics: string[], onNext: (eventData: any) => void): void {
        topics.forEach((topic) => {
            if (!this.activeTopics.has(topic)) {
                this.activeTopics.add(topic);
                this.allSubs.push(
                    this.sseService.getServerSentEvents(topic).subscribe({
                        next: onNext,
                        error: (error) => this.handleError(error, `${topic} event`),
                    })
                );
            }
        });
    }

    /**
     * Handles errors that occur in the service.
     * @param error - The error object.
     * @param context - The context in which the error occurred.
     */
    private handleError(error: any, context: string): void {
        console.error(`Error in ${context}:`, error);
    }

    /**
     * Clears sensitive data from the service.
     */
    private clearSensitiveData(): void {
        this.currentDevice = null;
    }

    /**
     * Validates a JWT token.
     * @param token - The token to validate.
     * @returns True if the token is valid, false otherwise.
     * @example
     * const isValid = this.isValidToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...');
     */
    private isValidToken(token: string): boolean {
        if (!token) return false;

        const parts = token.split('.');
        if (parts.length !== 3) return false;

        try {
            const decoded = decodeJwt(token) as JWTPayload;
            if (decoded.exp && Date.now() >= decoded.exp * 1000) return false;
        } catch (error) {
            return false;
        }

        return true;
    }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ServerSettingsSelectors from '../../store/selectors/server-settings.selector';
import * as ServerSettingsActions from '../../store/actions/server-settings.action';
import { AppState } from '../../store/appstate.model';
import { ServerSettings } from '../../models/server-settings.model';

@Injectable({
    providedIn: 'root',
})
export class ServerSettingsFacadeService {
    /**
     * Get the entire server settings state slice.
     */
    public serverSettingsState$ = this.store.select(ServerSettingsSelectors.selectServerSettingsFeature);

    /**
     * Get the server settings object from the store.
     */
    public serverSettings$ = this.store.select(ServerSettingsSelectors.selectServerSettings);

    /**
     * Get the loading state for server settings.
     */
    public loading$ = this.store.select(ServerSettingsSelectors.selectServerSettingsLoading);

    /**
     * Get the error state for server settings.
     */
    public error$ = this.store.select(ServerSettingsSelectors.selectServerSettingsError);

    public snapshot: ServerSettings | undefined;

    public constructor(private store: Store<AppState>) {
        // console.log('ServerSettingsFacadeService created');
        this.store.dispatch(ServerSettingsActions.loadServerSettings());

        this.store.select(ServerSettingsSelectors.selectServerSettings).subscribe((serverSettings) => {
            // console.log('Server settings updated', serverSettings);
            this.snapshot = serverSettings;
        });
    }

    /**
     * Dispatch an action to load server settings from persistent storage or an API.
     */
    public loadServerSettings(): void {
        this.store.dispatch(ServerSettingsActions.loadServerSettings());
    }

    /**
     * Dispatch an action to update the server settings in the store and backend.
     * @param serverSettings The new server settings to save.
     */
    public saveServerSettings(serverSettings: ServerSettings): void {
        this.store.dispatch(ServerSettingsActions.updateServerSettings({ serverSettings }));
    }
}

<h2 *ngIf="data.header" mat-dialog-title>{{ data.header }}</h2>

<mat-dialog-content *ngIf="data.message">
    <!--- innerHTML to set breaks in Text (<br>) -->
    <span [innerHTML]="data.message"></span>

    <mat-form-field *ngFor="let input of data.inputs">
        <mat-label>{{ input.label }}</mat-label>
        <input
            (change)="onInputChange(input.name, $event)"
            [placeholder]="input.placeholder ?? ''"
            [value]="input.value ?? null"
            matInput
        />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="data.buttons?.length">
    <button (click)="onClickOnButton(b)" *ngFor="let b of data.buttons" mat-button>{{ b.text }}</button>
</mat-dialog-actions>
